import { Address } from './Address';
import { Listing } from './Listing';
import { Media } from './Media';
import { Rating } from './Rating';
import { User } from './User';
// import { Notification } from './Notification';

export enum ProjectStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
  DRAFT = 'draft',
  PENDING_LAUNCH = 'pending_launch',
}

export enum ProjectType {
  FLAT = 'flat',
  HOME = 'home',
  FARM = 'farm',
}

export enum ProfitabilityType {
  RENT = 'rent',
  BUY = 'buy',
}

export interface Project {
  id: number;
  name: string;
  cover?: Media;
  medias?: Media[];
  description: string;
  startDate: Date;
  plans?: Media[];
  address: Address;
  shareAmount: number;
  numberOfFloors: number;
  sharePrice: number;
  status: ProjectStatus;
  projectType: ProjectType;
  profitabilityType: ProfitabilityType;
  area: number;
  levels: number;
  author: User;
  ratings?: Rating[];
  subscribers?: User[];
  listing?:Listing[];
  profitability: number;
}
