import { RouteComponentProps } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import Avatar from '../../components/Avatar/Avatar';
import FavoriteButton from '../../components/FavoriteButton/FavoriteButton';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import SliderCover from '../../components/SliderCover/SliderCover';
import { useFetchCurrentUser } from '../../services/hooks/useFetchCurrentUser';

import withAuth from '../../services/hoc/withAuth';
import RatingDetail from '../../components/RatingDetail/RatingDetail';
import AddRatingModalButton from '../../components/AddRatingModalButton/AddRatingModalButton';
import { useFetchProjectDetails } from '../../services/hooks/useFetchProjectDetails';
import RatingsDistribution from '../../components/RatingsDistribution/RatingsDistribution';
import StarOutlineIcon from '../../assets/icons/star-outline.svg';
import TextItem from '../../components/TextItem/TextItem';
//import AvatarList from '../../components/AvatarList/AvatarList';
import Divider from '../../components/Divider/Divider';
import ImageList from '../../components/ImageList/ImageList';
import { isUserFavorite } from '../../services/helpers/isUserFavorite';

//icons
import groupIcon from '../../assets/icons/group-stars.svg';
import shieldStarIcon from '../../assets/icons/shield-star.svg';
import partsIcon from '../../assets/icons/parts.svg';
import planIcon from '../../assets/icons/plan.svg';
import houseCertIcon from '../../assets/icons/house-certificate.svg';
//import investisorIcon from '../../assets/icons/investor.svg';
import goBackIcon from '../../assets/icons/chevron-right.svg';
import strategyIcon from '../../assets/icons/strategy.svg';
import ProjectBottomAction from '../../components/ProjectBottomAction/ProjectBottomAction';
import { style } from '../../constants/map.const';
import ProjectProfitCalculatorModal from '../../components/ProjectProfitCalculatorModal/ProjectProfitCalculatorModal';
import { computeAverageRatingsNote } from '../../services/helpers/computeAverageRatingsNote';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ModalLayout from '../../components/ModalLayout/ModalLayout';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import { PATH } from '../../constants/path.const';
import ShareButton from '../../components/ShareButton/ShareButton';
import AttachmentList from '../../components/AttachmentList/AttachmentList';
import { isUserDisabled } from '../../services/helpers/isUserDisabled';
import { useFetchCurrentUserLegalOwner } from '../../services/hooks/useFetchCurrentUserLegalOwner';
import BasicButton from '../../components/BasicButton/BasicButton';

const ProjectDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const router = useIntlRouter();
  const intl = useIntl();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [sharesBought, setSharesBought] = useState(0);
  const [isCalculatorModalOpen, setIsCalculatorModalOpen] = useState(false);

  const {
    data: project,
    isLoading,
    refetch: refetchProject,
  } = useFetchProjectDetails(match.params.id);
  const { data: user, refetch: refetchUser } = useFetchCurrentUser();


  const sliderMedias = project?.medias?.flatMap((media) => {
    return { url: media.mediaLink };
  });

  const plans = project?.medias?.flatMap((media, index) => {
    return { src: media.mediaLink, name: media.name };
  });

  const mapRef = useRef<HTMLDivElement>(document.createElement('div'));
  const getLocalization = () => {
    const map = new google.maps.Map(mapRef.current, {
      center: {
        lat: project?.address.geoLat
          ? parseFloat(`${project.address.geoLat}`)
          : 0,
        lng: project?.address.geoLng
          ? parseFloat(`${project.address.geoLng}`)
          : 0,
      },
      zoom: 14,
      disableDefaultUI: true,
      styles: style,
    });
    new google.maps.Marker({
      position: {
        lat: project?.address.geoLat
          ? parseFloat(`${project.address.geoLat}`)
          : 0,
        lng: project?.address.geoLng
          ? parseFloat(`${project.address.geoLng}`)
          : 0,
      },
      map,
    });
  };

  useEffect(() => {
    getLocalization();
  }, [project, intl.messages]);

  const scrollToReviews = () => {
    const element = document.getElementById('reviews_anchor');
    if (element)
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
  };

  const handleRedirectToProjectsList = () => {
    router.goBack();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        titleTranslationId={
          sharesBought === 1
            ? 'confirmation-modal.one-share-bought'
            : 'confirmation-modal.shares-bought'
        }
        titleValues={{ shareAmount: sharesBought.toString() }}
        descriptionTranslationId="confirmation-modal.find-invest"
        icon={houseCertIcon}
        buttonTranslationId="see_wallet"
        onClose={() => setIsConfirmationModalOpen(false)}
        onClick={() => {
          router.push(PATH.INVESTMENT_PORTFOLIO);
          setIsConfirmationModalOpen(false);
        }}
      />

      <Layout header={<Header />}>
        <div className="w-full m-5">
        <p className="flex items-center border border-slate-500 rounded-md p-2 lg:max-w-[200px]">← <a href="/" rel="noreferrer" className="text-slate-500 pl-3"><TranslatedMarkupText id="project-details.go-back-to-projects" /></a></p>
        </div>
        {/* <BasicButton translationId={'project-details.go-back-to-projects'} onClick={() => handleRedirectToProjectsList} /> */}
        {!isLoading && project && (
          <>
            <ProjectProfitCalculatorModal
              isOpen={isCalculatorModalOpen}
              onClose={(event) => {
                setIsCalculatorModalOpen(false);
                if (event > 0) {
                  setSharesBought(event);
                  setIsConfirmationModalOpen(true);
                }
              }}
              project={project}
              user={user}
              refetchUser={refetchUser}
            />
            <div className="w-full md:mx-auto">
              {sliderMedias && (
                <SliderCover project={project} images={sliderMedias} />
              )}
              <div className="flex w-full justify-between">
                <div className="flex p-5">
                  <Avatar
                    image={project.author.picture?.mediaLink}
                    isExpert={true}
                    userInitials={`${
                      project.author.firstname && project.author.firstname[0]
                    } ${project.author.lastname && project.author.lastname[0]}`}
                  />
                  <div className="ml-3 flex flex-col">
                    <span className="font-semibold">
                      {project.author.firstname} {project.author.lastname}
                    </span>
                    <span className="text-gray-400 text-xs">
                      <TranslatedMarkupText id="algae_expert" />
                    </span>
                  </div>
                </div>
                <div className="p-5 flex gap-x-2">
                  <ShareButton project={project} />
                  <FavoriteButton
                    isFavorite={isUserFavorite(user, project.id)}
                    projectId={project.id}
                    user={user}
                    onUpdate={() => void refetchUser}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-col">
                  <div className="ml-6">
                    <span className="font-semibold text-xl">
                      {project.name}
                    </span>
                  </div>
                  <div className="ml-6 mt-2">
                    <span className="text-base">
                      {project.numberOfFloors}{' '}
                      <TranslatedMarkupText id="project-details.floors" /> ·{' '}
                      {project.sharePrice}€/
                      <TranslatedMarkupText id="project-details.share" />
                    </span>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="mt-3">
                {project.ratings && (
                  <TextItem
                    icon={groupIcon}
                    label={
                      <TranslatedMarkupText id="project-details.community" />
                    }
                    boldLabel={`${computeAverageRatingsNote(
                      project.ratings
                    )}${intl.formatMessage({ id: 'project-details.on-five' })}`}
                    rightItem={
                      <span
                        className="text-link"
                        role="button"
                        tabIndex={0}
                        onClick={() => scrollToReviews()}
                      >
                        {project.ratings.length}{' '}
                        <TranslatedMarkupText id="project-details.reviews" />
                      </span>
                    }
                  />
                )}
                <TextItem
                  icon={shieldStarIcon}
                  label={<TranslatedMarkupText id="project-details.isr" />}
                  boldLabel="5/5"
                  rightItem={
                    <span
                      className="text-link"
                      role="button"
                      tabIndex={0}
                      onClick={() => setIsDetailModalOpen(true)}
                    >
                      {<TranslatedMarkupText id="project-details.details" />}
                    </span>
                  }
                />

                <ModalLayout isOpen={isDetailModalOpen}>
                  <div className="flex-col text-center">
                    <TranslatedMarkupText id="project-details.details-message" />
                    <p
                      className="text-link pt-5"
                      onClick={() => setIsDetailModalOpen(false)}
                    >
                      <TranslatedMarkupText id="alts.close" />
                    </p>
                  </div>
                </ModalLayout>

                <TextItem
                  icon={partsIcon}
                  label={`${project.shareAmount}`}
                  boldLabel={` · ${project.sharePrice}€/${intl.formatMessage({
                    id: 'project-details.share',
                  })}`}
                />
                {/*<TextItem
              icon={investisorIcon}
              label={`${avatars.length} investisseurs`}
              rightItem={<AvatarList users={project.user} maxToShow={3} />}
            />*/}
              </div>
              <Divider />
              <div className="text-pageTitle font-bold mt-6 mb-3 px-5 flex items-center">
                <span className="p-1.5 flex items-center justify-center bg-lightGrey rounded-lg">
                  <img src={strategyIcon} alt="" />
                </span>
                <span className="ml-3">
                  <TranslatedMarkupText id="project-details.strategy" />
                </span>
              </div>
              <div className="block mt-5 ml-6 mr-6">{project.description}</div>
              <div>
                <div className="text-pageTitle font-bold mt-6 mb-3 px-5 flex items-center">
                  <span className="p-1.5 flex items-center justify-center bg-lightGrey rounded-lg">
                    <img src={planIcon} alt="" />
                  </span>
                  <span className="ml-3">
                    <TranslatedMarkupText id="project-details.location" />
                  </span>
                </div>
                <div ref={mapRef} className="block w-full h-52"></div>
                <div className="text-pageTitle font-bold mt-6 mb-3 px-5 flex items-center">
                  <span className="p-1.5 flex items-center justify-center bg-lightGrey rounded-lg">
                    <img src={planIcon} alt="" />
                  </span>
                  <span className="ml-3">
                    <TranslatedMarkupText id="project-details.plans" />
                  </span>
                </div>
                {plans && (
                  // <ImageList isHorizontalScroll={true} images={plans} />
                  <AttachmentList images={plans} />
                )}
              </div>
              <div id="reviews_anchor" className="pb-36">
                {project.ratings && (
                  <>
                    <div className="text-pageTitle font-bold mt-6 px-5 flex items-center">
                      <span className="p-1.5 flex items-center justify-center bg-lightGrey rounded-lg">
                        <img src={StarOutlineIcon} alt="" />
                      </span>
                      <span className="ml-3">
                        <TranslatedMarkupText id="project-details.reviews" />
                        {` ( ${project.ratings.length} )`}
                      </span>
                    </div>
                    <div className="px-5 pt-2">
                      <RatingsDistribution ratings={project.ratings} />
                    </div>
                    <div className="mt-4 px-4">
                      {user && (
                        <AddRatingModalButton
                          projectId={project.id}
                          name={`${user.firstname} ${user.lastname}`}
                          onUpdate={() => refetchProject}
                        />
                      )}
                    </div>
                    <div className="mt-8">
                      {project.ratings.map((rating, idx) => (
                        <div key={idx} className="px-5 pt-10">
                          <RatingDetail key={rating.id} rating={rating} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <ProjectBottomAction
                project={project}
                onInvest={() => alert('invest')}
                onSimulate={() => setIsCalculatorModalOpen(true)}
                isDisabled={false}
              />
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default withAuth(ProjectDetails);
