import { AddRatingFormData } from '../../components/AddRatingModal/AddRatingModal';
import { Balance } from '../../models/Balance';
import { Favorite } from '../../models/Favorite';
import { Notification } from '../../models/Notification';
import {
  KYCDocument,
  MangopayKYCDocumentStatus,
} from '../../models/KYCDocument';
import { LegalOwner } from '../../models/LegalOwner';
import {
  MangopayCard,
  MangopayCardRegistration,
  MangopayPaymentCardDetails,
  UpdateMangopayCardRegistration,
} from '../../models/MangopayCard';
import { Media } from '../../models/Media';
import { Payment } from '../../models/Payment';
import { Project, ProjectStatus } from '../../models/Project';
import { Transaction } from '../../models/Transaction';
import { User } from '../../models/User';
import { Wallet } from '../../models/Wallet';
import { LegalOwnerFormData } from '../../pages/LegalOwnerForm/LegalOwnerForm';
import { UpdateUserFormData } from '../../pages/ProfileForm/ProfileForm';
import { ProjectFormData } from '../../pages/ProjectForm/ProjectForm';
import { handleApiResponseStatus } from '../helpers/handleApiResponseStatus';
import * as client from './client';

export const INTERNAL_SERVER_ERROR = 500;
export const FORBIDDEN = 403;
export const PAYMENT_ERROR = 402;
export const UNAUTHORIZED = 401;
export const SUCCESS_STATUS = 200;
export const POST_SUCCESS_STATUS = 201;

type ErrorData = {
  statusCode: number;
  message?: string;
};

export const getProjects = async (
  limit: number,
  skip: number,
  projectStatus?: ProjectStatus
): Promise<Project[]> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/projects?limit=${limit}&skip=${skip}${
      projectStatus ? `&projectStatus=${projectStatus}` : ''
    }`,
    withBearer: true,
  });

  return handleApiResponseStatus<Project[], { statusCode: number }>(
    await response.json()
  );
};

export const postProject = async (
  project: ProjectFormData
): Promise<({ status: number } & { statusCode: number }) | undefined> => {
  try {
    const response = await client.sendApiRequest({
      method: 'POST',
      endpoint: '/projects',
      withBearer: true,
      body: { ...project },
    });

    return (await response.json()) as { status: number } & {
      statusCode: number;
    };
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentUser = async (): Promise<User> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: '/users/me',
    withBearer: true,
  });

  return handleApiResponseStatus<User, { statusCode: number }>(
    await response.json()
  );
};

export const getFile = async (fileName: string): Promise<BlobPart[]> => {
  const response: Response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/files/${fileName}`,
    withBearer: true,
  });

  console.log(response);

  return handleApiResponseStatus<BlobPart[], ErrorData>(await response.json());
};

export const postFile = async (
  file: File
): Promise<({ error?: ErrorData } & Media) | undefined> => {
  try {
    const formData = new FormData();

    formData.append('file', file);
    const response: Response = await client.sendApiRequest({
      method: 'POST',
      endpoint: `/files`,
      body: formData,
      withBearer: true,
      isMultiPart: true,
    });

    return (await response.json()) as { error?: ErrorData } & Media;
  } catch (err) {
    console.log(err);
  }
};

export const deleteFile = async (
  fileId: string
): Promise<{ error?: ErrorData }> => {
  const response: Response = await client.sendApiRequest({
    method: 'DELETE',
    endpoint: `/files/${fileId}`,
    withBearer: true,
  });

  return handleApiResponseStatus<{ error: undefined }, ErrorData>(
    await response.json()
  );
};

export const postUser = async (
  user: User
): Promise<(User & { statusCode: number }) | undefined> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'POST',
      endpoint: '/users',
      withBearer: true,
      body: { ...user },
    });

    return (await response.json()) as { statusCode: number } & User;
  } catch (err) {
    console.log(err);
  }
};

export const patchUser = async (
  user: UpdateUserFormData
): Promise<(User & { statusCode: number }) | undefined> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'PATCH',
      endpoint: '/users/me',
      withBearer: true,
      body: { ...user },
    });

    return (await response.json()) as { statusCode: number } & User;
  } catch (err) {
    console.log(err);
  }
};

export const deleteCurrentUser = async (): Promise<{ error?: ErrorData }> => {
  const response: Response = await client.sendApiRequest({
    method: 'DELETE',
    endpoint: `/users/me`,
    withBearer: true,
  });

  return handleApiResponseStatus<{ error: undefined }, ErrorData>(
    await response.json()
  );
};

export const postNotification = async (
  projectId: number
): Promise<
  Partial<{ status: number } & { statusCode: number }> | undefined
> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'POST',
      endpoint: `/projects/${projectId}/notification`,
      withBearer: true,
    });

    if (response.status === POST_SUCCESS_STATUS) {
      return { status: POST_SUCCESS_STATUS };
    }

    return (await response.json()) as { statusCode: number };
  } catch (err) {
    console.log(err);
  }
};

export const deleteNotification = async (
  projectId: number
): Promise<
  Partial<{ status: number } & { statusCode: number }> | undefined
> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'DELETE',
      endpoint: `/projects/${projectId}/notification`,
      withBearer: true,
    });

    if (response.status === SUCCESS_STATUS) {
      return { status: SUCCESS_STATUS };
    }

    return (await response.json()) as { statusCode: number };
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentUserNotifications = async (): Promise<Notification[]> => {
  const response: Response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/notifications`,
    withBearer: true,
  });

  return handleApiResponseStatus<Notification[], { statusCode: number }>(
    await response.json()
  );
};

export const postFavorite = async (
  projectId: number
): Promise<
  Partial<{ status: number } & { statusCode: number }> | undefined
> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'POST',
      endpoint: `/projects/${projectId}/favorite`,
      withBearer: true,
    });

    if (response.status === POST_SUCCESS_STATUS) {
      return { status: POST_SUCCESS_STATUS };
    }

    return (await response.json()) as { statusCode: number };
  } catch (err) {
    console.log(err);
  }
};

export const deleteFavorite = async (
  projectId: number
): Promise<
  Partial<{ status: number } & { statusCode: number }> | undefined
> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'DELETE',
      endpoint: `/projects/${projectId}/favorite`,
      withBearer: true,
    });

    if (response.status === SUCCESS_STATUS) {
      return { status: SUCCESS_STATUS };
    }

    return (await response.json()) as { statusCode: number };
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentUserFavorites = async (): Promise<Favorite[]> => {
  const response: Response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/favorites`,
    withBearer: true,
  });

  return handleApiResponseStatus<Favorite[], { statusCode: number }>(
    await response.json()
  );
};

export const postProjectRating = async (
  ratingFormData: AddRatingFormData
): Promise<
  Partial<{ status: number } & { statusCode: number }> | undefined
> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'POST',
      endpoint: `/projects/${ratingFormData.projectId}/rating`,
      withBearer: true,
      body: { ...ratingFormData },
    });

    if (response.status === POST_SUCCESS_STATUS) {
      return { status: POST_SUCCESS_STATUS };
    }

    return (await response.json()) as { statusCode: number };
  } catch (err) {
    console.log(err);
  }
};

export const deleteProjectRating = async (
  projectId: number
): Promise<
  Partial<{ status: number } & { statusCode: number }> | undefined
> => {
  try {
    const response: Response = await client.sendApiRequest({
      method: 'DELETE',
      endpoint: `/projects/${projectId}/rating`,
      withBearer: true,
    });

    if (response.status === SUCCESS_STATUS) {
      return { status: SUCCESS_STATUS };
    }

    return (await response.json()) as { statusCode: number };
  } catch (err) {
    console.log(err);
  }
};

export const getProjectDetails = async (id: string): Promise<Project> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/projects/${id}`,
    withBearer: true,
  });

  return handleApiResponseStatus<Project, { statusCode: number }>(
    await response.json()
  );
};

export const getCurrentProjectBalances = async (id:number): Promise<Balance[]> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/projects/${id}/balances`,
    withBearer: true,
  });

  return handleApiResponseStatus<Balance[], { statusCode: number }>(
    await response.json()
  );
};

export const getCurrentUserPaymentCards = async (): Promise<MangopayCard[]> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/wallet/cards`,
    withBearer: true,
  });

  return handleApiResponseStatus<MangopayCard[], { statusCode: number }>(
    await response.json()
  );
};

export const postCurrentUserMangopayCardRegistration =
  async (): Promise<MangopayCardRegistration> => {
    const response = await client.sendApiRequest({
      method: 'POST',
      endpoint: `/users/me/wallet/cards`,
      withBearer: true,
    });

    return handleApiResponseStatus<
      MangopayCardRegistration,
      { statusCode: number }
    >(await response.json());
  };

export const postTokenizeMangopayCardDetails = async (
  mangopayCardDetailsEndpoint: string,
  mangopayPaymentCardDetails: MangopayPaymentCardDetails
): Promise<string> => {
  const headers = new Headers();

  headers.append('Content-type', 'application/x-www-form-urlencoded');

  const response = await client.request({
    headers: headers,
    method: 'POST',
    url: mangopayCardDetailsEndpoint,
    body: new URLSearchParams({ ...mangopayPaymentCardDetails }),
  });

  return await response.text();
};

export const postCurrentUserMangopayTokenizedCardDetails = async (
  updateMangopayCardRegistration: UpdateMangopayCardRegistration
): Promise<MangopayCardRegistration> => {
  const response = await client.sendApiRequest({
    method: 'POST',
    endpoint: `/users/me/wallet/cards/tokenized`,
    withBearer: true,
    body: { ...updateMangopayCardRegistration },
  });

  return handleApiResponseStatus<
    MangopayCardRegistration,
    { statusCode: number }
  >(await response.json());
};

export const postCurrentUserBuyProjectShares = async (
  projectId: number,
  shareAmount: number
): Promise<Transaction | { statusCode: number; message: string }> => {
  const response = await client.sendApiRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/buy`,
    withBearer: true,
    body: { shareAmount },
  });

  return await response.json();
};

export const getCurrentUserBalances = async (): Promise<Balance[]> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/balances`,
    withBearer: true,
  });

  return handleApiResponseStatus<Balance[], { statusCode: number }>(
    await response.json()
  );
};

export const getCurrentUserLegalOwner = async (): Promise<LegalOwner> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/wallet/legalOwner`,
    withBearer: true,
  });

  return handleApiResponseStatus<LegalOwner, { statusCode: number }>(
    await response.json()
  );
};

export const postCurrentUserLegalOwner = async (
  legalOwnerFormData: LegalOwnerFormData
): Promise<LegalOwner> => {
  const response = await client.sendApiRequest({
    method: 'POST',
    endpoint: `/users/me/wallet/account`,
    body: { ...legalOwnerFormData },
    withBearer: true,
  });

  return handleApiResponseStatus<LegalOwner, { statusCode: number }>(
    await response.json()
  );
};

export const getCurrentUserWallet = async (): Promise<Wallet> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/wallet/mangopay`,
    withBearer: true,
  });

  return handleApiResponseStatus<Wallet, { statusCode: number }>(
    await response.json()
  );
};

export const postCurrentUserWallet = async (): Promise<
  Wallet | { statusCode: number }
> => {
  const response = await client.sendApiRequest({
    method: 'POST',
    endpoint: `/users/me/wallet`,
    withBearer: true,
  });

  return await response.json();
};

export const postCurrentUserTopUpWallet = async (
  amount: number,
  fees: number,
  cardId: string
): Promise<Payment | { statusCode: number; message: string }> => {
  const response = await client.sendApiRequest({
    method: 'POST',
    endpoint: `/users/me/wallet/topUp`,
    body: { amount, fees, cardId },
  });

  return await response.json();
};

export const getCurrentUserKYCDocumentsByStatus = async (
  mangopayKYCDocumentStatus: MangopayKYCDocumentStatus
): Promise<KYCDocument[]> => {
  const response = await client.sendApiRequest({
    method: 'GET',
    endpoint: `/users/me/wallet/kyc?status=${mangopayKYCDocumentStatus}`,
    withBearer: true,
  });

  return handleApiResponseStatus<KYCDocument[], { statusCode: number }>(
    await response.json()
  );
};
