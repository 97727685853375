import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Project, ProjectStatus } from '../../models/Project';
import InputShareAmount from '../Forms/InputShareAmount/InputShareAmount';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import ShieldStarIcon from '../../assets/icons/shield-star.svg';
import StackedIconAndText from '../StackedIconAndText/StackedIconAndText';
import InvestorsIcon from '../../assets/icons/investor.svg';
import WarningIcon from '../../assets/icons/warning.svg';
import AddedFavoriteIcon from '../../assets/icons/home-heart.svg';
import { computeAverageRatingsNote } from '../../services/helpers/computeAverageRatingsNote';
import BottomAction from '../BottomAction/BottomAction';
import InputRange from '../Forms/InputRange/InputRange';
import SwipeableModalLayout from '../SwipeableModalLayout/SwipeableModalLayout';
import { getFormattedDateFromNumberOfDays } from '../../services/helpers/getFormattedDateFromNumberOfDays';
import { computeRoundedNumber } from '../../services/helpers/computeRoundedNumber';
import { computeProfitPerMonth } from '../../services/helpers/computeProfitPerMonth';
import { computeProfitPerYear } from '../../services/helpers/computeProfitPerYear';
import { computeProfitPerDay } from '../../services/helpers/computeProfitPerDay';
import InvestModal from '../InvestModal/InvestModal';
import SwipeableModalHeader from '../SwipeableModalHeader/SwipeableModalHeader';
import { User } from '../../models/User';
import FinishedProjectModale from '../FinishedProjectModale/FinishedProjectModale';
import BasicToast from '../BasicToast/BasicToast';
import { useFetchCurrentProjectBalances } from '../../services/hooks/useFetchCurrentProjectBalance';
import { useFetchCurrentUserLegalOwner } from '../../services/hooks/useFetchCurrentUserLegalOwner';

import { isUserFavorite } from '../../services/helpers/isUserFavorite';
import {
  deleteFavorite,
  POST_SUCCESS_STATUS,
  postFavorite,
  SUCCESS_STATUS,
} from '../../services/networking/apiClient';
import ErrorToast from '../ErrorToast/ErrorToast';

interface Props {
  isOpen: boolean;
  onClose: (numberOfShares: number) => void;
  project: Project;
  user?: User;
  refetchUser: () => void;
}

const MONTHS_IN_A_YEAR = 12;
const DAYS_IN_A_YEAR = 365.25;
// const DEFAULT_PROFIT_DAYS_RANGE = DAYS_IN_A_YEAR * 2;
const DAYS_PER_MONTH = Math.floor(DAYS_IN_A_YEAR / MONTHS_IN_A_YEAR);
const MAX_PROFIT_DAYS_RANGE = DAYS_IN_A_YEAR * 20;

const ProjectProfitCalculatorModal: React.FC<Props> = ({
  isOpen,
  onClose,
  project,
  user,
  refetchUser,
}) => {
  const { data: balances, isLoading: areBalancesLoading } =
    useFetchCurrentProjectBalances(project.id);
  const { data: relatedLegalOwner } = useFetchCurrentUserLegalOwner();
  const investors = balances?.length;

  // TO-DO remove this mock
  project.profitability = 10;
  const [numberOfShares, setNumberOfShares] = useState(
    2000 / project.sharePrice
  );
  const intl = useIntl();
  const [profitDaysRange, setProfitDaysRange] = useState(365 * 7);
  const [isAddFavoriteSuccessToastOpen, setIsAddFavoriteSuccessToastOpen] =
    useState(false);
  const [
    isDeleteFavoriteSuccessToastOpen,
    setIsDeleteFavoriteSuccessToastOpen,
  ] = useState(false);
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);

  const averageRatingsNote = useMemo(
    () => project.ratings && computeAverageRatingsNote(project.ratings),
    [project]
  );

  const profitPerMonth = useMemo(
    () =>
      computeProfitPerMonth(
        project.profitability,
        project.sharePrice,
        numberOfShares
      ),
    [numberOfShares, project]
  );

  const rentPerYear = useMemo(
    () =>
      computeProfitPerYear(
        project.profitability,
        project.sharePrice,
        numberOfShares
      ),
    [numberOfShares, project]
  );

  const profitPerDate = useMemo(
    () =>
      computeRoundedNumber(
        computeProfitPerDay(
          project.profitability,
          numberOfShares,
          project.sharePrice
        ) * profitDaysRange,
        2
      ),
    [project, numberOfShares, profitDaysRange]
  );

  const rangeValueAsDate = useMemo(() => {
    return getFormattedDateFromNumberOfDays(profitDaysRange, intl);
  }, [profitDaysRange, intl]);

  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  const presentingElement = useRef<HTMLIonModalElement>(
    document.createElement('ion-modal')
  );

  const [
    isMinNumberOfSharesValidationError,
    setIsMinNumberOfSharesValidationError,
  ] = useState(false);

  const [isUserMustBeLoggedInToastOpen, setIsUserMustBeLoggedInToastOpen] =
    useState(false);
  const [
    isUserMustHaveACompletedProfileToastOpen,
    setIsUserMustHaveACompletedProfileToastOpen,
  ] = useState(false);
  const [
    isUserMustHaveALegalOwnerToastOpen,
    setIsUserMustHaveALegalOwnerToastOpen,
  ] = useState(false);
  const [isProjectNotForSaleToastOpen, setIsProjectNotForSaleToastOpen] =
    useState(false);
  const [isProjectIsCloseModaleOpen, setIsProjectIsCloseModaleOpen] =
    useState(false);

  const isFavorite = isUserFavorite(user, project.id);

  const verifyIfUserProfileIsCompleteAndIfProjectHasSharesToSell = () => {
    if (!user) {
      setIsUserMustBeLoggedInToastOpen(true);

      return false;
    } else if (!user.firstname || !user.lastname) {
      setIsUserMustHaveACompletedProfileToastOpen(true);

      return false;
    } else if (!relatedLegalOwner) {
      setIsUserMustHaveALegalOwnerToastOpen(true);

      return false;
    } else if (project.listing![0].sellerId !== project.author.id) {
      setIsProjectNotForSaleToastOpen(true);

      return false;
    } else {
      if (numberOfShares === 0) {
        setIsMinNumberOfSharesValidationError(true);

        return false;
      } else {
        return true;
      }
    }
  };

  const handleClickOnBottomCalculatorButton = async () => {
    if (project.status === ProjectStatus.CLOSED)
      setIsProjectIsCloseModaleOpen(true);
    if (project.status === ProjectStatus.PENDING_LAUNCH) {
      if (isFavorite) {
        const response = await deleteFavorite(project.id);
        if (response?.status === SUCCESS_STATUS) {
          setIsDeleteFavoriteSuccessToastOpen(true);
        } else {
          setIsErrorToastOpen(true);
        }
      } else {
        const response = await postFavorite(project.id);
        if (response?.status === POST_SUCCESS_STATUS) {
          setIsAddFavoriteSuccessToastOpen(true);
        } else {
          setIsErrorToastOpen(true);
        }
      }

      refetchUser();
    }

    if (project.status === ProjectStatus.ACTIVE) {
      const isUserProfileIncompleteOrProjectHasNoSharesToSell =
        verifyIfUserProfileIsCompleteAndIfProjectHasSharesToSell();
      if (!isUserProfileIncompleteOrProjectHasNoSharesToSell) return;

      setIsInvestModalOpen(true);
    }
  };

  return (
    <>
      <InvestModal
        isOpen={isInvestModalOpen}
        onClose={(sharesBought) => {
          setIsInvestModalOpen(false);
          if (sharesBought > 0) {
            onClose(sharesBought);
          }
        }}
        selectedNumberOfShares={numberOfShares}
        project={project}
        user={user}
        ionicPresentingElement={presentingElement.current}
      />
      <SwipeableModalLayout
        isOpen={isOpen}
        onClose={() => onClose(0)}
        header={
          <SwipeableModalHeader
            translationId="project-profit-calculator.yield-estimate"
            onClose={() => onClose(0)}
          />
        }
      >
        <BasicToast
          isOpen={isUserMustBeLoggedInToastOpen}
          onClose={() => setIsUserMustBeLoggedInToastOpen(false)}
          messageTranslationId="payment-project-errors-toast.user-must-be-connected"
          icon={WarningIcon}
        />
        <BasicToast
          isOpen={isUserMustHaveACompletedProfileToastOpen}
          onClose={() => setIsUserMustHaveACompletedProfileToastOpen(false)}
          messageTranslationId="payment-project-errors-toast.user-must-have-a-complete-profile"
          icon={WarningIcon}
        />
        <BasicToast
          isOpen={isUserMustHaveALegalOwnerToastOpen}
          onClose={() => setIsUserMustHaveALegalOwnerToastOpen(false)}
          messageTranslationId="payment-project-errors-toast.user-must-have-a-legal-owner"
          icon={WarningIcon}
        />
        <BasicToast
          isOpen={isProjectNotForSaleToastOpen}
          onClose={() => setIsProjectNotForSaleToastOpen(false)}
          messageTranslationId="payment-project-errors-toast.project-not-for-sale"
          icon={WarningIcon}
        />
        <BasicToast
          isOpen={isAddFavoriteSuccessToastOpen}
          onClose={() => setIsAddFavoriteSuccessToastOpen(false)}
          titleTranslationId="favorites.add-favorite-toast.title"
          messageTranslationId="favorites.add-favorite-toast.message"
          icon={AddedFavoriteIcon}
        />
        <BasicToast
          isOpen={isDeleteFavoriteSuccessToastOpen}
          onClose={() => setIsDeleteFavoriteSuccessToastOpen(false)}
          titleTranslationId="favorites.delete-favorite-toast.title"
          messageTranslationId="favorites.delete-favorite-toast.message"
          icon={AddedFavoriteIcon}
        />
        <ErrorToast
          isOpen={isErrorToastOpen}
          onClose={() => void setIsErrorToastOpen(false)}
        />

        {project.status === ProjectStatus.CLOSED && (
          <FinishedProjectModale
            isOpen={isProjectIsCloseModaleOpen}
            setIsOpen={setIsProjectIsCloseModaleOpen}
            setIsCalculatorModaleOpen={setIsProjectIsCloseModaleOpen}
            project={project}
            user={user}
            refetchUser={() => void refetchUser()}
          />
        )}

        <div className="relative rounded-t-2xl bg-white h-full flex flex-col w-full">
          <div className="px-5 mt-5">
            <div className="text-3xl w-full flex justify-between">
              <div>
                <TranslatedMarkupText
                  id="project-profit-calculator.price"
                  values={{ price: computeRoundedNumber(profitPerDate, 2) }}
                />
              </div>
            </div>
            <div>
              <InputRange
                onChange={(value: number) => setProfitDaysRange(value)}
                value={profitDaysRange}
                step={DAYS_PER_MONTH}
                maxValue={MAX_PROFIT_DAYS_RANGE}
              />
            </div>
            <div className="flex justify-end text-sm font-semibold text-darkGrey">
              {rangeValueAsDate}
            </div>
          </div>
          <div className="px-5 flex flex-row justify-between items-center mt-5">
            <div className="font-bold">
              <span className="text-3xl">
                <TranslatedMarkupText
                  id="project-profit-calculator.price"
                  values={{ price: computeRoundedNumber(profitPerMonth, 2) }}
                />
              </span>
              <span className="text-2xl">
                <TranslatedMarkupText id="project-profit-calculator.per-month" />
              </span>
            </div>
            <div className="flex flex-col text-sm text-right">
              <span>
                <TranslatedMarkupText id="project-profit-calculator.rent" />
              </span>
              <span>
                {computeRoundedNumber(rentPerYear, 2)}
                <TranslatedMarkupText id="project-profit-calculator.price-unit-per-year" />
              </span>
            </div>
          </div>
          <hr className="m-5" />
          <div className="mt-5 px-5 h-14 flex flex-row justify-between items-center">
            <div className="w-3/5 flex flex-row">
              <InputShareAmount
                onChange={(value: number) => {
                  if (value > 0) setIsMinNumberOfSharesValidationError(false);
                  setNumberOfShares(value);
                }}
                value={numberOfShares}
                maxValue={project.shareAmount}
              />
            </div>
            <div className="w-1/2 flex flex-col text-right">
              <div>
                {`${numberOfShares * project.sharePrice}`}
                <TranslatedMarkupText id="project-profit-calculator.price-unit" />
              </div>
              <div className="text-mediumGrey">
                {`${project.sharePrice}`}
                <TranslatedMarkupText id="project-profit-calculator.price-unit" />
                <TranslatedMarkupText id="project-profit-calculator.by-share" />
              </div>
            </div>
          </div>
          {isMinNumberOfSharesValidationError && (
            <div className="mt-2 mx-5 text-sm text-error">
              <TranslatedMarkupText id="project-profit-calculator.min-number-of-shares-error" />
            </div>
          )}
          <div className="mt-8">
            {project.ratings && project.ratings.length > 0 && (
              <div className="p-5 bg-lightGrey">
                <StackedIconAndText
                  titleTranslationId="project-profit-calculator.isr.title"
                  messageTranslationId="project-profit-calculator.isr.message"
                  icon={ShieldStarIcon}
                  titleBoldValue={`${
                    averageRatingsNote ?? 0
                  }${intl.formatMessage({
                    id: 'project-profit-calculator.on-five',
                  })}`}
                />
              </div>
            )}
            <div className="p-5 bg-lightGrey">
              <StackedIconAndText
                titleTranslationId="project-profit-calculator.investors.title"
                messageTranslationId="project-profit-calculator.investors.message"
                icon={InvestorsIcon}
                titleBoldValue={`${investors ? investors : 0}`}
              />
            </div>
          </div>
          <div className="mt-auto">
            <BottomAction
              title={
                <TranslatedMarkupText
                  id={
                    numberOfShares > 1
                      ? 'project-profit-calculator.footer.title-multiple'
                      : 'project-profit-calculator.footer.title-single'
                  }
                  values={{
                    total: numberOfShares * project.sharePrice,
                    shareAmount: numberOfShares,
                  }}
                />
              }
              subtitle={
                <span>
                  <TranslatedMarkupText id="project-profit-calculator.profit" />{' '}
                  {computeRoundedNumber(profitPerMonth, 2)}
                  <TranslatedMarkupText id="project-profit-calculator.price-unit-per-month" />
                </span>
              }
              ratings={averageRatingsNote ?? 0}
              totalRating={project.ratings?.length}
              buttonTranslationId={
                project.status !== ProjectStatus.PENDING_LAUNCH
                  ? 'project-details.invest'
                  : !isFavorite
                  ? 'project-details.interested'
                  : 'project-details.no-more-interested'
              }
              onClick={() => handleClickOnBottomCalculatorButton()}
              isFixed={false}
              // isDisabled={}
            />
          </div>
        </div>
      </SwipeableModalLayout>
    </>
  );
};

export default ProjectProfitCalculatorModal;
